<template>
	<div class="view view-login" :class="[ app.day_moment, { 'is-login': form === 'login' } ]">
		<home-background :day_moment="app.day_moment" />
		<div class="bg"></div>
		<div class="content">
			<div class="wrap">
				<transition name="slide" mode="out-in">

					<div v-if="!form" class="main-buttons" key="main-buttons">
						<div class="logo"></div>
						<div class="button login" v-hammer:tap="() => set_form(LOGIN)">{{ $t(LOGIN) }}</div>
						<div class="button signup" v-hammer:tap="() => signUp()">{{ $t(SIGNUP) }}</div>
					</div>

					<div v-else class="window" key="window">
						<!-- FORGOT PASSWORD -->
						<template
							v-if="form === FORGOT_PASSWORD"
						>
							<ValidationObserver
								ref="passwordReset"
								v-slot="{ handleSubmit }"
								tag="div"
								class="validation-observer"
							>
								<form
									class="form"
									method="post"
									@submit.prevent="handleSubmit(passwordReset)"
									@keyup.enter='handleSubmit(passwordReset)'
								>
									<div class="title">{{ $t(FORGOT_PASSWORD) }}</div>

									<validation-provider
										mode="lazy"
										name="email"
										rules="required|email"
										v-slot="{ errors }"
										tag="div"
										class="line"
									>
										<label for="forgot-email" class="label">{{ $t('e-mail') }}</label>
										<input type="email" v-model="email" id="forgot-email" class="input">
										<span v-if="errors.length" class="error input-text">{{ errors[0] }}</span>
									</validation-provider>

									<div class="line buttons">
										<ui-button type="login" color="back" :loading="loading" v-hammer:tap="() => set_form('')">{{ $t('back') }}</ui-button>
										<ui-button type="login" color="confirm" :loading="loading" v-hammer:tap="() => handleSubmit(passwordReset)">{{ $t('confirm') }}</ui-button>
									</div>
									<button type="submit"/>
								</form>
							</ValidationObserver>
						</template>

						<template
							v-else-if="form === REQUEST_SENT"
						>
							<div class="form">
								<div class="title">{{ $t(FORGOT_PASSWORD) }}</div>
								<div class="message" v-html="REQUEST_SENT"></div>
								<div class="email">{{email}}</div>
								<div class="line buttons">
									<ui-button type="login" color="confirm" :loading="loading" v-hammer:tap="() => set_form('')">{{ $t('home') }}</ui-button>
								</div>
							</div>
						</template>

						<!-- LOGIN -->
						<ValidationObserver
							ref="login"
							v-slot="{ handleSubmit }"
							tag="div"
							class="validation-observer"
						>
							<form
								v-if="form === LOGIN"
								class="form"
								method="post"
								@submit.prevent="handleSubmit(login)"
								@keyup.enter='handleSubmit(login)'
							>
								<div class="title">{{ $t(LOGIN) }}</div>

								<span v-if="invalidCredentials" class="login-error">{{ $t('invalid_credentials') }}</span>

								<validation-provider
									mode="lazy"
									name="email"
									rules="required|email"
									v-slot="{ errors }"
									tag="div"
									class="line"
								>
									<label for="login-email" class="label">{{ $t('e-mail') }}</label>
									<input type="email" v-model="email" id="login-email" class="input">
									<span v-if="errors.length" class="error">{{ errors[0] }}</span>
								</validation-provider>

								<validation-provider
									mode="lazy"
									name="password"
									rules="required"
									v-slot="{ errors }"
									tag="div"
									class="line"
								>
									<label for="login-password" class="label">{{ $t('password') }}</label>
									<input type="password" v-model="password" id="login-password" class="input">
									<span v-if="errors.length" class="error">{{ errors[0] }}</span>
								</validation-provider>

								<div class="line">
									<div class="forgot-button" v-hammer:tap="() => set_form(FORGOT_PASSWORD)">{{ $t('forgot_password') }}</div>
								</div>

								<div class="line buttons">
									<ui-button type="login" color="back" v-hammer:tap="() => set_form('')">{{ $t('back') }}</ui-button>
									<ui-button type="login" color="confirm" :loading="loading" v-hammer:tap="() => handleSubmit(login)">{{ $t('confirm') }}</ui-button>
								</div>
								<button type="submit"/>
							</form>
						</ValidationObserver>
					</div>
				</transition>
			</div>
		</div>
		<div class="hospital"></div>
		<div class="langs">
			<div v-if="language !== 'en'" class="lang" v-hammer:tap="() => set_lang('en')">EN</div>
			<div v-if="language !== 'fr'" class="lang" v-hammer:tap="() => set_lang('fr')">FR</div>
		</div>
	</div>
</template>

<script>
import Apollo from '@/apollo/apollo-client'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { mapState, mapGetters } from 'vuex'
import { LANGUAGE } from '@/js/factory/language'
import { getValidation } from '@/js/utils/validations'
import { login } from '@/js/jwt/jwt'
import HomeBackground from '@/components/home-background'
import Vue from 'vue'

export default {
	name: 'view-login',
	data() {
		const EMAIL_NOT_REGISTERED = Vue.i18n.translate('email_not_registered')
		const FORGOT_PASSWORD = 'Password reset'
		const REQUEST_SENT = Vue.i18n.translate('request_sent')
		const LOGIN = 'login'
		const SIGNUP = 'signup'
		return {
			EMAIL_NOT_REGISTERED,
			FORGOT_PASSWORD,
			REQUEST_SENT,
			LOGIN,
			SIGNUP,
			loading: false,
			message: '',
			form: '',
			step: 1,
			avatar: 0,
			email: null,
			password: null,
			passwordConfirmation: null,
			currentLocale: null,
			invalidCredentials: false,
			formElements: [],
			layout: null,
		}
	},
	components: {
		ValidationProvider,
		ValidationObserver,
		HomeBackground
	},
	watch: {
		email(n, o) {
			if (!n && o) return
			this.invalidCredentials = false
		},
		password(n, o) {
			if (!n && o) return
			this.invalidCredentials = false
		}
	},
	computed: {
		...mapState({
			loggedIn: state => state.user.loggedIn,
			subjectsToH: state => state.schema.data && state.schema.data.subjectsToH
				? state.schema.data.subjectsToH
				: null,
			schemaId: state => state.schema.data
				? state.schema.data.id
				: null,
			manageUser: state => state.user.manageUser,
			manageForms: state => state.user.manageForms
		}),
		...mapGetters(['app', 'user']),
		language() {
			return LANGUAGE.getData()
		},
	},
	methods: {
		set_lang(lang) {
			LANGUAGE.setData({ definition: 'Language', data: lang })
			location.reload()
		},
		signUp() {
			this.$router.push({ name: 'signup' })
		},
		getValidation,
		clearForm(form = '') {
			this.set_form(form)
			Object.assign(this, {
				email: null,
				password: null,
				passwordConfirmation: null,
			})
		},
		async apolloQueries() {
			const response = await Apollo.query('CURRENT_CLIENT')
			if (response) {
				const { id } = response
				if (id) {
					await this.$store.dispatch('clientSet', {
						value: {
							id
						}
					})
				}
			}
			const value = await Apollo.query('SCHEMA', { id: process.env.VUE_APP_APOLLO_SCHEMA_ID })
			if (value) {
				await this.$store.dispatch('schemaSet', { value })
			}
		},
		hide_signup_message() {
			this.$store.commit('EDIT_USER_STATE', { signup_success: false })
		},
		async handleLogin() {
			if (!this.loading) {
				this.loading = true
				const { email, password, LOGIN } = this
				if (email && password) {
					const isUserLogged = await login({ email, password })
					this.loading = false
					if (isUserLogged) {
						await this.$store.dispatch('get_current_user')
						const { getters: { user: { logged_user: { onboarding, reviewer } = {} } = {} } = {} } = this.$store
						if (reviewer) await this.$store.dispatch('get_pending_comments')
						if (onboarding === 'is_validated') await this.$router.push({ name: 'home' })
						else await this.$router.push({ name: 'signup' })
					} else {
						this.clearForm(LOGIN)
						this.$refs.login.reset()
						this.invalidCredentials = true
					}
				}
			}
		},
		set_form(form) {
			this.form = form
			if (!form) {
				this.email = ''
				this.password = ''
				const { login } = this.$refs
				if (login) login.reset()
			}
		},
		set_avatar(n) {
			if (this.avatar === n) {
				this.avatar = 0
			} else {
				this.avatar = n
			}
		},
		async login() {
			await this.handleLogin()
		},
		async passwordReset() {
			const { EMAIL_NOT_REGISTERED, REQUEST_SENT, email } = this
			const mutation = 'REQUEST_PASSWORD_RESET'
			const variables = { email }
			const isRequestSent = await Apollo.mutate(mutation, variables)
			if (!isRequestSent) {
				this.$refs.passwordReset.errors.email.push(EMAIL_NOT_REGISTERED)
				return
			}
			this.set_form(REQUEST_SENT)
		},
		getRuleFromSchema(fieldName) {
			const object = this.subjectsToH
			return getValidation({ object, fieldName })
		}
	},
	mounted() {
		this.apolloQueries()
		this.$store.dispatch('set_main_loader', { main_loader: false })
		this.currentLocale = this.$i18n.locale()
		const data = this.currentLocale
		LANGUAGE.setData({ data })

		this.$emit('loaded')
	}
}
</script>

<style lang="stylus" scoped>
	@import '../assets/css/variables'
	@import '../assets/css/commun'


	#app.mobile
		.view-login
			.signup-message
				width calc(100% - 32px)



	.slide-enter-active, .slide-leave-active
		transition 0.25s easeOutQuart

	.slide-enter
		transform translateX(50px)
		opacity 0

	.slide-leave-to
		transform translateX(-50px)
		opacity 0


	.view-login
		background #E5FBFF bottom center no-repeat
		background-size cover
		height 100%
		transition 0.25s easeOutQuart
		transition-delay 0.25s

		&.night
			background-image url(../assets/img/home/sky-night.svg)
			.bg
				background-image url(../assets/img/login/clinic-bg-night.svg)
			.hospital
				background-image url(../assets/img/login/clinic-front-night.svg)
			.langs .lang
				background-color alpha(#fff, 10%)
				color #fff
				&:hover
					background-color alpha(#fff, 25%)


		.langs
			position absolute
			right 12px
			top 12px
			flex center center
			z-index 1
			.lang
				width 48px
				height 48px
				flex center center
				cursor pointer
				font-weight 700
				border-radius 4px
				background-color alpha(#FF6133, 10%)
				color #FF6133
				&:hover
					background-color alpha(#FF6133, 20%)
					
		.bg
			absolute 0
			background url(../assets/img/login/clinic-bg-day.svg) bottom center no-repeat
			background-size cover

		>>> .home-background
			.img.clinic
				display none

		.hospital
			position absolute
			left 43%
			bottom 6.2%
			width 700px
			height calc(95% - 64px)
			background url(../assets/img/login/clinic-front-day.svg) bottom center no-repeat
			background-size contain

		.content
			position absolute
			left 0
			right 0
			top calc(50% - 250px)
			width 100%
			height 450px
			background-color alpha(#0A172B, 95%)

			.wrap
				position absolute
				right 60%
				// margin 0 100px 0 0
				width 410px
				height 100%
				flex center center
				flex-direction column

				.logo
					margin 0 0 32px 0
					height 175px
					width 100%
					background url(../assets/img/login/logo-full.svg) center center no-repeat
					background-size contain

				.main-buttons
					width 100%

					&.slide-enter
						transform translateX(50px)

					.button
						flex center center
						width 100%
						height 56px
						margin 0 0 24px 0
						background red
						background-size 100% 100%
						border-radius 10px
						color #fff
						text-transform uppercase
						font-weight 700
						box-shadow 0px 3px 6px alpha(#000, 30%)
						font-size 2.4rem
						cursor pointer
						user-select none

						&:last-child
							margin 0

						&.signup
							background-color yellow
							&:active
								background-color darken(yellow, 10%)

						&:active
							transform translateY(4px)
							box-shadow none
							background-color darken(red, 10%)

			.window
				width 100%
				height 100%
				text-align left

				&.slide-leave-to
					transform translateX(-50px)

				.validation-observer
					height 100%

				.form
					display block
					height 100%
					padding 32px 0

					&.form-enter-active, &.form-leave-active
						transition 0.25s easeOutQuart
					&.form-enter
						transform translateX(100%)
						opacity 0
					&.form-leave-to
						transform translateX(-100%)
						opacity 0

					.title
						color white
						margin 0 0 32px 0
						padding 0 0 8px 0
						border-bottom 2px solid #fff
						text-align left
						font-size 2rem
						text-transform uppercase
						font-weight 700

					button[type="submit"]
						position fixed
						left 2000px
						top 2000px
						opacity 0

					.line
						margin 0 0 16px 0

						&:last-child
							margin 0

						&.buttons
							position absolute
							right 0
							bottom 32px
							margin 0
							.ui-button
								margin 0 0 0 16px
								font-weight 700

						.label
							text-align left
							color white
							display block
							margin 0 0 4px 0px
							padding 0 0 0 16px
							&::first-letter
								text-transform capitalize

						.input
							display block
							width 100%
							padding 0 16px
							height 40px
							appearance none
							color dark
							border none
							background-color #fff
							border-radius 40px
							font inherit
							font-size 1.4rem
							color inherit
							outline none

							&:focus
								box-shadow 0 0 0 2px red

						select.input
							background #fff url(../assets/img/chevron-down.svg) right 12px center no-repeat
							background-size 16px 16px

						.error
							z-index 10000
							position absolute
							background-color white
							display block
							min-height 24px
							flex flex-start center
							color #f44336
							font-weight 700
							font-size 1.2rem
							line-height unset
							border black solid 1px
							padding 8px 12px
							user-select none
							box-shadow 0 0 16px 2px alpha(#000, 50%)
							border-radius 8px
							&.checkbox
								left 30px
								top 0
							&.text-input,
							&.password-input
								left 16px
								top 70px

						.forgot-button
							margin 16px
							display inline-block
							cursor pointer
							color #fff
							text-decoration underline
							&:hover
								text-decoration none

						.images
							flex flex-start flex-start
							flex space-between flex-start
							flex-wrap wrap
							padding 8px 0 0 0

							.image
								width (220px / 3 - 12px)
								height (220px / 3 - 12px)
								margin 0 0 16px 0
								background-color #ddd
								border-radius 50%
								cursor pointer
								transition transform 0.25s easeOutQuart
								// overflow hidden

								&.selected
									box-shadow 0 0 0 4px red
									transform scale(1.1)

								img
									width 100%
									height 100%
									border-radius 50%

				.close
					position absolute
					left 8px
					top 8px
					width 40px
					height 40px
					background url(../assets/img/arrow-left.svg) center center no-repeat
					background-size 32px auto
					cursor pointer

		.signup-message
			position absolute
			right 16px
			top 16px
			max-width 350px
			padding 24px 24px 24px 76px
			background #8BC34A url(../assets/img/mail-white.svg) left 16px center no-repeat
			background-size 44px auto
			font-size 1.4rem
			line-height 18px
			color #fff
			cursor pointer
			&.signup-message-enter-active, &.signup-message-leave-active
				transition 0.25s easeOutQuart
			&.signup-message-enter, &.signup-message-leave-to
				transform translateX(100%)
				opacity 0
			p
				margin 0 0 8px 0
				&:last-child
					margin 0

		.change-locale
			position absolute
			left 16px
			top 16px
			width 64px
			height 64px
			background-color dark
			border-radius 50%
			font-size 1.4rem
			&:before
				content ''
				position absolute
				top calc(50% - 4px)
				right 12px
				width 0
				height 0
				border-style solid
				border-width 8px 6px 0 6px
				border-color #fff transparent transparent transparent
			select
				position absolute
				top 0
				bottom 0
				left 50%
				padding 0 20px 0 4px
				transform translateX(-50%)
				height 100%
				background none
				border none
				outline none
				appearance none
				font inherit
				color #fff
				text-align center
				flex center center
				font-weight 700
				text-transform uppercase
				option
					text-align center
					color dark
					padding 8px 0
					text-transform uppercase
	.errorCustom
		min-height 24px
		display flex
		-webkit-box-align center
		align-items center
		-webkit-box-pack start
		justify-content flex-start
		margin 4px 0 0 0
		padding-left 32px
		background url(../assets/img/login/alert-triangle.svg) left top no-repeat
		background-size 24px 24px
		color #f44336
		font-weight 700
		font-size 1.1rem
		line-height 12px

.title
	text-align center
	font-size 1.1em
	font-weight bold
	color white
	padding-bottom 10px
	border-bottom 2px solid white
	margin-bottom 30px
	text-transform uppercase
.message
	color white
.email
	color white
	font-weight bold
	margin 20px 0 0 0


@media (max-width: 1200px)
	.view-login
		&.is-login
			.content
				// transform translateY(-100%)
				height 450px
		.hospital
			display block
			left 50%
			transform translateX(-50%)
			width 95%
			z-index 1
			height 100%
			max-height 90%
		.content
			top 50%
			transform translateY(-50%)
			height 290px
			z-index 2
			transition height 0.25s easeOutQuart
			.wrap
				right auto
				left 50%
				transform translateX(-50%)
				margin 0
				width 100%
				max-width (410px + 32px)
				padding 0 32px
				.logo
					margin 0 0 16px 0
					height 110px
				.main-buttons
					.button
						margin 0 0 12px 0
						height 44px


</style>
